export function limitAndFormatDecimal(value: string, maxNumber: number, minNumber: number): number | string {
  // To match numbers with three or more decimal places
  const patternThreeDecimal = /^\d+(\.\d{3,})$/;
  const patternIsDecimal = /^[0-9]+[.]$/;

  value = value.trim().replace(',', '.');

  if (value === '.' || patternIsDecimal.test(value)) {
    return value;
  }

  let parsedValue = parseFloat(value);

  if (parsedValue > maxNumber) {
    parsedValue = maxNumber;
  } else if (parsedValue < minNumber) {
    parsedValue = minNumber;
  } else if (patternThreeDecimal.test(parsedValue.toString())) {
    parsedValue = parseFloat(parsedValue.toFixed(2));
  }

  if (isNaN(parsedValue)) {
    return '';
  }

  return parsedValue;
}

export function limitAndFormatNumber(value: string, maxNumber: number, minNumber: number): number | string {
  // To match numbers with one or more decimal places
  const patternThreeDecimal = /^\d+(\.\d{1,})$/;

  let parsedValue = parseFloat(value);

  if (parsedValue > maxNumber) {
    parsedValue = maxNumber;
  } else if (parsedValue < minNumber) {
    parsedValue = minNumber;
  } else if (patternThreeDecimal.test(value.toString())) {
    parsedValue = parseFloat(parsedValue.toFixed(0));
  }

  if (isNaN(parsedValue)) {
    return '';
  }

  return parsedValue;
}
