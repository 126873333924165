import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_CUSTOM_FORMAT_PROVIDER, PeriodDate, isMoment } from '@globals';
import * as moment from 'moment';

@Component({
  standalone: true,
  selector: 'app-custom-period-date-selector',
  imports: [MatDatepickerModule, MatFormFieldModule, FormsModule, MatInputModule],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    MAT_DATE_CUSTOM_FORMAT_PROVIDER,
  ],
  styleUrl: './custom-period-date-selector.component.scss',
  templateUrl: './custom-period-date-selector.component.html',
})
export class CustomPeriodDateSelectorComponent {
  @Input() header: string | undefined =
    $localize`:@@app.InputElement.customPeriodDateSelector:Or select a custom period`;
  @Input() periodStart: Date | moment.Moment | undefined;
  @Input() periodEnd: Date | moment.Moment | undefined;

  @Output() periodChanged = new EventEmitter<PeriodDate>();

  readonly currentDate = new Date();

  maxStartDate: Date | undefined;
  minEndDate: Date | undefined;

  onDateChange(): void {
    const start: Date | undefined = isMoment(this.periodStart) ? this.periodStart.toDate() : this.periodStart;
    const end: Date | undefined = isMoment(this.periodEnd) ? this.periodEnd.toDate() : this.periodEnd;

    this.maxStartDate = end;
    this.minEndDate = start;

    this.periodChanged.emit({ start, end });
  }
}
