const userAgent = window.navigator.userAgent;
const platform = window.navigator.platform;

export function isAndroid(): boolean {
  return /Android/.test(userAgent);
}

export function isIOS(): boolean {
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  return iosPlatforms.indexOf(platform) !== -1;
}

export function isMobile(): boolean {
  return isAndroid() || isIOS();
}
