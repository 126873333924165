export const defaultPath = './assets/images/assets.svg';

export const PATHS = {
  IPS: `${defaultPath}#ips`,
  Upsell: `${defaultPath}#upsell`,
  IOSEn: `${defaultPath}#ios-en`,
  IOSNl: `${defaultPath}#ios-nl`,
  AndroidEn: `${defaultPath}#android-en`,
  AndroidNl: `${defaultPath}#android-nl`,
  RoundedStar: `${defaultPath}#rounded-star`,
};

export type IDS =
  | 'contact-persons'
  | 'diagnoses'
  | 'medication'
  | 'patient-details'
  | 'procedures'
  | 'activity'
  | 'results'
  | 'sources'
  | 'vaccinations'
  | 'allergy-intolerances';
