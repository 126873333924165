import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import saveAs from 'file-saver';
import { FileSaver, MobileFile, WebFile } from '../models';

export function base64ToUint8Array(content: string): Uint8Array {
  const byteCharacters = atob(content);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  return new Uint8Array(byteNumbers);
}

export class MobileFileSaver implements FileSaver {
  async save(file: MobileFile): Promise<void> {
    await Filesystem.writeFile({
      data: file.data,
      path: file.path,
      directory: Directory.Cache,
      encoding: Encoding.UTF8,
    }).then(async (result) => {
      await Share.share({
        url: result.uri,
      });
    });
  }
}

export class WebFileSaver implements FileSaver {
  async save(file: WebFile): Promise<void> {
    const blob = new Blob([file.data], { type: file.type });
    saveAs(blob, file.name);
  }
}
