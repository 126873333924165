export function termsOfUse(): string {
  return $localize.locale === 'nl' ? 'https://digi.me/nl/legal/terms' : 'https://digi.me/legal/terms';
}

export function privacyPolicy(): string {
  return $localize.locale === 'nl' ? 'https://digi.me/nl/legal/privacy' : 'https://digi.me/legal/privacy';
}

export function help(): string {
  return $localize.locale === 'nl' ? 'https://digi.me/nl/questions' : 'https://digi.me/questions';
}
