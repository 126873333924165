export type Device = 'desktop' | 'tablet' | 'mobile';

export function checkScreenType(innerWidth?: number): Device | undefined {
  const screenWidth = innerWidth ?? window.innerWidth;

  // ! TODO: Compare with bootstrap breakpoints
  if (screenWidth >= 992) return 'desktop';
  if (screenWidth >= 576 && screenWidth < 992) return 'tablet';
  if (screenWidth < 576) return 'mobile';

  return undefined;
}
