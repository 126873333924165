import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

export async function closeEmbeddedBrowser(): Promise<void> {
  if (Capacitor.getPlatform() === 'ios') {
    try {
      await Browser.close();
    } catch {
      // Do nothing
    }
  }
}
