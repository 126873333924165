import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  PeriodDate,
  PredefinedPeriodDateType,
  getFirstDayOfMonth,
  getFirstDayOfWeek,
  getFirstDayOfYear,
} from '@globals';
import { ButtonComponent } from '../../button';

@Component({
  standalone: true,
  selector: 'app-predefined-period-date',
  styleUrls: ['./predefined-period-date.component.scss'],
  imports: [ButtonComponent],
  template: `
    <app-button
      type="secondary"
      (clicked)="emitSelectedPeriodDate()"
      [label]="this.getPeriodDateButtonTitle(type)"
      icon="date_range">
    </app-button>
  `,
})
export class PredefinedPeriodDateComponent {
  @Input({ required: true }) type!: PredefinedPeriodDateType;
  @Output() readonly selectedPeriod = new EventEmitter<PeriodDate>();

  /**
   * Emits the selected period date based on the given type.
   */
  emitSelectedPeriodDate(): void {
    this.selectedPeriod.emit(this.selectPeriod(this.type));
  }

  /**
   * Returns the title for the period date button based on the provided type.
   * @param type - The predefined period date type ('Week', 'Month', or 'Year').
   * @returns The title for the period date button.
   * @throws Error if an invalid predefined period date type is selected.
   */
  getPeriodDateButtonTitle(type: PredefinedPeriodDateType): string {
    switch (type) {
      case 'Week':
        return $localize`:@@app.InputElement.predefinedPeriodDateWeek:This Week`;
      case 'Month':
        return $localize`:@@app.InputElement.predefinedPeriodDateMonth:This Month`;
      case 'Year':
        return $localize`:@@app.InputElement.predefinedPeriodDateYear:This Year`;
      default:
        throw new Error(`Invalid predefined period date type (${type}) selected`);
    }
  }

  /**
   * Selects an period date based on the given type.
   * @param type The predefined period date type ('Week', 'Month', or 'Year').
   * @returns An object representing the selected period  date, with 'start' and 'end' properties.
   * @throws Error if an invalid predefined period date type is selected.
   */
  private selectPeriod(type: PredefinedPeriodDateType): PeriodDate {
    switch (type) {
      case 'Week':
        return { start: getFirstDayOfWeek(), end: new Date() };
      case 'Month':
        return { start: getFirstDayOfMonth(), end: new Date() };
      case 'Year':
        return { start: getFirstDayOfYear(), end: new Date() };
      default:
        throw new Error(`Invalid predefined period date type (${type}) selected`);
    }
  }
}
