import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { Urls } from '@globals';

@Component({
  standalone: true,
  selector: 'app-wdx-footer',
  templateUrl: './wdx-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxFooterComponent {
  @Input() compact = true;
  readonly appVersion = environment.version;

  get termsOfUseLink(): string {
    return Urls.termsOfUse();
  }

  get privacyPolicyLink(): string {
    return Urls.privacyPolicy();
  }
}
