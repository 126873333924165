import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { ButtonComponent } from '../button';

@Component({
  standalone: true,
  selector: 'app-overview-footer',
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
  imports: [CommonModule, LottieComponent, ButtonComponent],
  templateUrl: './overview-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewFooterComponent implements OnChanges, OnInit {
  @Input() loading: boolean | null = false;
  @Input() enableAddMore = true;
  @Input() rowData: any;
  @Output() readonly linkedSources = new EventEmitter<void>();
  loadingRowData = true;
  text: string = $localize`:@@app.animation.unlockingAndLoadingYourRecords:Unlocking and loading your records`;

  options: AnimationOptions = {
    path: '/assets/animations/digi-unlock-loop.json',
  };

  private timerId: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.loadingRowData = !!this.loading;
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading'] && !this.loadingRowData) {
      return;
    }

    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    if (changes['loading'] && !changes['loading'].currentValue) {
      this.setReadyAnimation();
      this.text = $localize`:@@app.animation.upToDate:Up to date`;

      this.timerId = setTimeout(() => {
        this.clearAnimation();
      }, 10000);
    } else {
      this.setLoadingAnimation();
      this.text = $localize`:@@app.animation.unlockingAndLoadingYourRecords:Unlocking and loading your records`;
    }
    this.cdr.detectChanges();
  }

  routeToLinkedSources(): void {
    this.linkedSources.emit();
  }

  setReadyAnimation(): void {
    this.options = {
      path: 'assets/animations/digi-unlock-success.json',
      loop: false,
    };
  }

  setLoadingAnimation(): void {
    this.options = {
      path: 'assets/animations/digi-unlock-loop.json',
      loop: true,
    };
  }

  clearAnimation(): void {
    this.loadingRowData = false;
    this.cdr.detectChanges();
  }
}
