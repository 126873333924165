import { ObservationStorage } from '@globals/constants';
import { CodeSystems } from '@hl7fhir/codesystems';

export function getStorageFolderBySystemCode(systemCodes: string[]): string {
  for (const code of systemCodes) {
    if (code === CodeSystems.APPLE_HEALTH) {
      return ObservationStorage.FOLDERS.APPLE_HEALTH;
    } else if (code === `${CodeSystems.DIGI_ME}.1.1.1`) {
      return ObservationStorage.FOLDERS.SELF_MEASUREMENTS;
    }
  }

  throw new Error('No storage folder found for the given system code');
}
