import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

/**
 * Get the URL handler for the mobile platforms. This is used to open URLs in the embedded browser.
 * For web, this function returns undefined as the browser is already embedded.
 * @returns The URL handler for the current platform.
 */
export function getUrlHandler(): ((url: string) => Promise<void>) | undefined {
  let urlHandler: ((url: string) => Promise<void>) | undefined;

  if (Capacitor.isNativePlatform()) {
    urlHandler = async (url: string) => {
      await Browser.open({ url });
    };
  }
  return urlHandler;
}
