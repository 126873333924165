import { Params } from '@angular/router';

/**
 * Retrieves the library value from the given params object.
 * @param params - The params object containing the state parameter.
 * @returns The library value if found, otherwise undefined.
 */
export function getLibraryFrom(params: Params): string | undefined {
  const state = decodeURIComponent(params['state']);
  const stateParams = new URLSearchParams(state);
  return stateParams.get('library') ?? undefined;
}

export function getReauthFrom(params: Params): string | undefined {
  const state = decodeURIComponent(params['state']);
  const stateParams = new URLSearchParams(state);
  return stateParams.get('reauth') ?? undefined;
}
