import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const v3CodeSystemTimingEvent: CodeSystemFlattened = {
  id: 'v3-TimingEvent',
  codes: {
    AC: {
      display: 'Before meal',
      'nl-NL': 'Voor de maaltijd',
    },
    ACD: {
      display: 'Before lunch',
      'nl-NL': 'Voor de lunch',
    },
    ACM: {
      display: 'Before breakfast',
      'nl-NL': 'Voor het ontbijt',
    },
    ACV: {
      display: 'Before dinner',
      'nl-NL': 'Voor het avondeten',
    },
    C: {
      display: 'Meal',
      'nl-NL': 'Maaltijd',
    },
    HS: {
      display: 'Prior beginning sleep',
      'nl-NL': 'Voor aanvang van de slaap',
    },
    IC: {
      display: 'Between meals',
      'nl-NL': 'Tussen de maaltijden',
    },
    ICD: {
      display: 'Between lunch and dinner',
      'nl-NL': 'Tussen de lunch en avondeten',
    },
    ICM: {
      display: 'Between breakfast and lunch',
      'nl-NL': 'Tussen ontbijt en lunch',
    },
    ICV: {
      display: 'Between dinner and the hour of sleep',
      'nl-NL': 'Tussen het avondeten en het gaan slapen',
    },
    PC: {
      display: 'After meal',
      'nl-NL': 'Na de maaltijd',
    },
    PCD: {
      display: 'After lunch',
      'nl-NL': 'Na de lunch',
    },
    PCM: {
      display: 'After breakfast',
      'nl-NL': 'Na het ontbijt',
    },
    PCV: {
      display: 'After dinner',
      'nl-NL': 'Na het avondeten',
    },
    WAKE: {
      display: 'WAKE',
      'nl-NL': 'Wakker',
    },
    CD: {
      display: 'Lunch',
      'nl-NL': 'Lunch',
    },
    CM: {
      display: 'Breakfast',
      'nl-NL': 'Ontbijt',
    },
    CV: {
      display: 'Dinner',
      'nl-NL': 'Avondeten',
    },
  },
};
