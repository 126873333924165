<div class="d-flex align-items-center align-text-center gap-1 gap-lg-2 mb-2">
  <ng-content [select]="app-back-arrow"></ng-content>

  <h2 class="m-0 flex-fill"
      data-cy="header-text">{{ text }}</h2>

  <svg *ngIf="icon"
       class="d-inline-block header-menu-icon"
       aria-hidden="true">
    <use data-cy="header-icon"
         attr.xlink:href="./assets/images/assets.svg#{{icon}}"></use>
  </svg>
</div>