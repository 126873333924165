import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../button';
import { ModalStepComponent } from './modal-step.component';

@Component({
  standalone: true,
  selector: 'app-modal-stepper',
  imports: [CommonModule, NgbModalModule, MatIconModule, ButtonComponent],
  templateUrl: './modal-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalStepperComponent implements AfterContentInit {
  @ContentChildren(ModalStepComponent, { descendants: true }) public steps: QueryList<ModalStepComponent> | undefined;
  @Input() autoOpen = false;
  @ViewChild('modal') private modal!: TemplateRef<any>;
  public activeIndex = 0;

  private modalService = inject(NgbModal);

  private opened?: NgbModalRef = undefined;

  get headerToRender() {
    return this.activeStep?.getHeader() ?? null;
  }

  get bodyToRender() {
    return this.activeStep?.getBody() ?? null;
  }

  get footerToRender() {
    return this.activeStep?.getFooter() ?? null;
  }

  get canGoBack() {
    return this.activeIndex > 0;
  }

  private get activeStep() {
    return this.steps?.get(this.activeIndex) ?? null;
  }

  @Input() public set selectedIndex(value: number) {
    this.activeIndex = value || 0;
  }

  open() {
    if (this.opened !== undefined) {
      this.close('opened other');
    }

    setTimeout(() => {
      this.opened = this.modalService.open(this.modal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static', // Prevent closing by clicking outside of the modal
        keyboard: false, // Prevent closing by pressing the escape key
      });
    }, 10);
  }

  close(reason: string) {
    if (this.opened) {
      this.opened.close(reason);
      this.opened = undefined;
    }
  }

  previous() {
    this.selectedIndex = Math.max(0, this.activeIndex - 1);
  }

  next() {
    this.selectedIndex = Math.min(this.lastIndex(), this.activeIndex + 1);
  }

  first() {
    this.selectedIndex = 0;
  }

  last() {
    this.selectedIndex = this.lastIndex();
  }

  ngAfterContentInit() {
    if (this.autoOpen) {
      this.open();
    }
  }

  private lastIndex() {
    return (this.steps?.length ?? 1) - 1;
  }
}
