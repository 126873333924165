import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const GlucoseMeetmomentenLOINCCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.53.11.1--20200618120422',
  systems: {
    'http://loinc.org': {
      '14770-2': {
        display: 'Blood Glucose: fasting',
        'nl-NL': 'Glucose: nuchter',
      },
      '14743-9': {
        display: 'Blood Glucose: non fasting',
        'nl-NL': 'Glucose: niet nuchter',
      },
      '14760-3': {
        display: 'Blood Glucose: 2 hours post-meal',
        'nl-NL': 'Glucose: 2 uur na maaltijd',
      },
    },
  },
};
