<footer class="d-flex justify-content-between align-items-center flex-column flex-lg-row"
        [class.flex-lg-column]="compact">
  <div class="d-flex flex-column flex-sm-row flex-wrap justify-content-between gap-3 flex-grow-1 flex-basis-0 w-100 text-center"
       [class.justify-content-lg-end]="!compact"
       [class.order-lg-3]="!compact">
    <a [attr.href]="privacyPolicyLink"
       target="_blank"
       class="link-dark link-primary-hover link-underline-opacity-0 link-underline-opacity-100-hover flex-grow-1 flex-basis-0 text-sm-end"
       i18n="@@app.footer.privacyPolicy">Privacy Policy</a>
    <span [class.order-lg-1]="!compact"
          class="order-1 order-sm-0">{{ appVersion }}</span>
    <a [attr.href]="termsOfUseLink"
       target="_blank"
       class="link-dark link-primary-hover link-underline-opacity-0 link-underline-opacity-100-hover flex-grow-1 flex-lg-grow-0 flex-basis-0 flex-lg-basis-auto text-sm-start"
       i18n="@@app.footer.termsOfUse">Terms of use</a>
  </div>
  <div class="d-flex flex-wrap justify-content-center gap-2 py-2 align-items-center"
       [class.order-lg-2]="!compact">
    <a href="https://worlddataexchange.com"
       target="_blank">
      <svg style="height: 40px; width: 115px;"
           aria-labelledby="wdx">
        <use xlink:href="./assets/images/assets.svg#wdx"></use>
      </svg></a>
  </div>
  <p class="text-center m-0 flex-grow-1 flex-basis-0"
     [class.text-lg-start]="!compact"><small>
      <span>© 2024</span>&nbsp;<a class="link-dark link-primary-hover"
         href="https://worlddataexchange.com"
         target="_blank"
         i18n="@@app.hamburger.wdx"
         id="wdx">World Data Exchange B.V.</a></small></p>
</footer>